export function steps(){
    let truevalues = [100,250,1000,2500,5000,10000,15000,20000];
    let i = document.getElementById("slider1").value;
    let id="p"+truevalues[i];
    let price = document.getElementById(id);
    if(id != "p20000"){
        document.getElementById("PAYGPrice").innerHTML=price.value;
        document.getElementById("PAYGPrice").className = "fs-1 fw-bold text-quasr";
        document.getElementById("PAYGCurrency").innerHTML= "€";
        document.getElementById("PAYGVat").innerHTML= "/month";
        document.getElementById("NumberOfMAA").innerHTML = truevalues[i].toLocaleString('be-BE');
    }else{
        document.getElementById("PAYGCurrency").innerHTML= "";
        document.getElementById("PAYGPrice").innerHTML= price.value;
        document.getElementById("PAYGPrice").className = "fs-3 fw-bold text-quasr";
        document.getElementById("NumberOfMAA").innerHTML ="20.000+";
        document.getElementById("PAYGVat").innerHTML ="";
    }
}