function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function enableTracking() {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({event:'gtm.js','gtm.start':new Date().getTime()});
  var gtm = document.createElement('script');
  gtm.id = 'gtm';
  gtm.async = true;
  gtm.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-MHJFGWT';
  document.body.appendChild(gtm);
  console.log('Tracking Enabled');
}

function disableTracking($) {
  $('#gtm').remove();
  window.dataLayer = [];
  console.log('Tracking Disabled');
}

export function checkConsent(modal) {
  console.log('Checking Consent');
  var cqf = getCookie('_cqf') === 'true';
  var cqa = getCookie('_cqa') === 'true';
  if (!cqf) {
      console.log('Capture Consent');
      modal.show();
  } else if (cqa) {
      enableTracking();
  }
}

export function saveConsent($,modal) {
  var old_cqa = getCookie('_cqa') === 'true';
  var cqf = $('#cqf').is(':checked');
  var cqa = $('#cqa').is(':checked');
  setCookie('_cqf',cqf,{expires:365});
  setCookie('_cqa',cqa,{expires:365});
  console.log('Consent Saved');
  modal.hide();
  if (cqa) {
      if (!old_cqa) enableTracking();
  } else if (old_cqa) {
      disableTracking($);
  }
}