import $ from 'jquery';
import { Modal } from 'bootstrap';
import { saveConsent, checkConsent } from './cookies';
import { steps } from './slider';

$(() => {
    $('#slider1').on('input', () => steps());
    $('#slider').show();
    $('#cookieSettings').show();
    const modal = new Modal('#cookies');
    $('#save').on('click', () => saveConsent($,modal));
    checkConsent(modal);
});